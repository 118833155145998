<template>
  <div class="order-box">
    <!-- 页面失效 -->
    <div class="invalid">
      <img src="../assets/detail/default.png">
      <p>订单已过期 暂不支持查看</p>
    </div>
  </div>
</template>

<script>

export default {
  name: "orderExpired",

  data() {
    return {
      
    };
  },

  props: {},

  watch: {},

  mounted: function () {
    
  },

  created: function () {
    
  },
  methods:{
   
  },
};
</script>

<style scoped lang="scss">
  .order-box{
    width: 100%;
    height: 100%;
    background: #F6F7F8;
  }
  .invalid {
    width: 100%;
    height: 100%;
    background: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    img {
      width: 3.2rem;
      height: 3.2rem;
    }
    p {
      font-size: 0.32rem;
      color: #222222;
      text-align: center;
      font-weight: 400;
    }
  }
</style>
